import { useAuthContext } from "../../hooks/useAuthContext";
import { useCityContext } from "./useCityContext";
import API_URL from '../../api/ApiUrl';

const CityRow = ({id,title,country}) => {
    const {dispatch} = useCityContext();
    const {user} = useAuthContext();

    const handleClick = async () =>{

        if(!user) {
            return;
        }

        const response = await fetch(API_URL.DELETE_CITY + id,{
            method:'DELETE',
            headers:{
                'Authorization': `Bearer ${user.token}` 
            }
        });
        const json = await response.json();
        console.log(json);
        if(response.ok){
            dispatch({type:'DELETE_CITY',payload:json});
        }
    }



    return (
        <tr key={id}>
            <th scope="row">{id}</th>
            <td>{title}</td>
            <td>{country}</td>
            <td><span onClick={handleClick}>Delete</span></td>
        </tr>
    )
}

export default CityRow;