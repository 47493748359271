import { useParams } from 'react-router';
import { useState, useEffect } from "react";
import { useAuthContext } from '../../hooks/useAuthContext';
import API_URL from "../../api/ApiUrl";
import useFetch from '../../api/useFetch';


const EditBusiness = () => {

    const {id} = useParams(); 
    const {user} = useAuthContext();

    const {readError, isPending, data} = useFetch(API_URL.FETCH_SINGLE_BUSINESS_BY_ID + "/" + id);

    const [title,setTitle] = useState('');
    const [seo_name,setSeo_Name] = useState('');
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [about,setAbout] = useState('');
    const [whatsapp_number,setWhatsapp_Number] = useState('');
    const [video_url,setVideo_Url] = useState('');
    const [website_url,setWebsite_Url] = useState('');
    const [city,setCity] = useState('');
    const [country,setCountry] = useState('');
    const [tags,setTags] = useState('');

    const [error,setError] = useState(null);
    const [msg,setMsg] = useState(null);
    const [emptyFields,setEmptyFields] = useState([]);

    const [allCities,setAllCities] = useState([]);
    const [cityError,setCityError] = useState(null);

    const cityChange = (event) =>{ 
        setCity(event.target.value);
        setCountry(event.currentTarget.selectedOptions[0].attributes[0].nodeValue);
        //console.log();
   }

   useEffect(()=>{
    const fetchAllCities = async () => {
        const response = await fetch(API_URL.FETCH_ALL_CITY);
        const json = await response.json();

        if(response.ok){
          setAllCities(json);
        }
        else{
          setAllCities(null);
          setCityError('No city data found!');
        }
    }
    fetchAllCities();


    //set the business data into the state
    if(data){
        setTitle(data.title);
        setSeo_Name(data.seo_name);
        setUsername(data.username);
        setPassword(data.password);
        setAbout(data.about);
        setWhatsapp_Number(data.whatsapp_number);
        setVideo_Url(data.video_url);
        setWebsite_Url(data.website_url);
        setCity(data.city);
        setCountry(data.country);
        setTags(data.tags);
    }

},[data]);

const handleSubmit = async (e) => {

    e.preventDefault();

    try {
        if(!user) {
            setError("You must be logged in");
            return;
        }

        const businessData = {title,seo_name, username,password,about,whatsapp_number,video_url,website_url,city,country,tags};

        const response = await fetch(API_URL.PATCH_BUSINESS + "/" + id,{
            method: 'PATCH',
            headers:{
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${user.token}` 
            },
           
            body: JSON.stringify(businessData),
           
           
        });
        const json = await response.json();
        if(!response.ok){
            setMsg('');
            setError(json.error);
            if(json.requiredFields){
                setEmptyFields(json.requiredFields);
                console.log(json.requiredFields);
            }               
        }
        if(response.ok){
            data = json.business;
            setMsg(json.msg);
            setEmptyFields([]);
            console.log("New Business edited",json);
    
        }
    } catch (error) {
        setError(error.message);
    }


}

    return (
        <main className="">
         <div class="container ">

         { readError && <div>{ readError }</div> } 
         { isPending && <div>Loading data please wait...</div> }

             <form onSubmit={handleSubmit}>

                <br></br>
                <h1 className="h3 mb-3 fw-normal">Edit Business</h1>
                 <div class="row">
               

                 <div class="col-6">
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="titleLabel"
                        className={`form-control ${emptyFields.includes('title')? 'error':''}`}
                        onChange={(event)=> setTitle(event.target.value)}
                        value={title}
                        />
                        <label for="titleLabel">Title</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="seoLabel"
                        className={`form-control ${emptyFields.includes('seo_name')? 'error':''}`}
                        onChange={(event)=> setSeo_Name(event.target.value)}
                        value={seo_name}
                        />
                        <label for="seoLabel">Seo Name</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="usernameLabel"
                        className={`form-control ${emptyFields.includes('username')? 'error':''}`}
                        onChange={(event)=> setUsername(event.target.value)}
                        value={username}
                        />
                        <label for="usernameLabel">Username</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="passwordLabel"
                        className={`form-control ${emptyFields.includes('password')? 'error':''}`}
                        onChange={(event)=> setPassword(event.target.value)}
                        value={password}
                        />
                        <label for="passwordLabel">Password</label>
                    </div>
                    <br/>
                    <div class="form-floating">
                        <textarea class="form-control"
                        id="aboutLabel"
                        className={`form-control ${emptyFields.includes('about')? 'error':''}`}
                        onChange={(event)=> setAbout(event.target.value)}
                        value={about}
                        style={{height:250}}
                       />
                        <label for="aboutLabel">About</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="whatsappLabel"
                        className={`form-control ${emptyFields.includes('whatsapp_number')? 'error':''}`}
                        onChange={(event)=> setWhatsapp_Number(event.target.value)}
                        value={whatsapp_number}
                        />
                        <label for="whatsappLabel">Whatsapp Number</label>
                    </div>
                </div>
                <div class="col-6">
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="videoLabel"
                        className={`form-control ${emptyFields.includes('video_url')? 'error':''}`}
                        onChange={(event)=> setVideo_Url(event.target.value)}
                        value={video_url}
                        />
                        <label for="videoLabel">Video Url</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="websiteLabel"
                        className={`form-control ${emptyFields.includes('website_url')? 'error':''}`}
                        onChange={(event)=> setWebsite_Url(event.target.value)}
                        value={website_url}
                        />
                        <label for="websiteLabel">Website Url</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <select 
                        id="cityLabel"
                        className={`form-control ${emptyFields.includes('city')? 'error':''}`}
                        onChange={cityChange}
                        value={city}
                        >
                            <option value="">Select</option>
                            { allCities && allCities.map((city) => (
                                 <option data-pg={city.country} value={city.title}>{city.title}</option>
                                ))
                            }
                        </select>
                        <label for="cityLabel">City</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        readOnly
                        type="text" 
                        id="countryLabel"
                        className={`form-control ${emptyFields.includes('country')? 'error':''}`}
                        onChange={(event)=> setCountry(event.target.value)}
                        value={country}
                        />
                        <label for="countryLabel">Country</label>
                    </div>
                    <br/>
                    <div className="form-floating">
                        <input 
                        type="text" 
                        id="tagsLabel"
                        className={`form-control ${emptyFields.includes('tags')? 'error':''}`}
                        onChange={(event)=> setTags(event.target.value)}
                        value={tags}
                        />
                        <label for="tagsLabel">Tags</label>
                    </div>

                    <br></br>
                    <button className="btn btn-primary">Edit Business</button>
                    {error && <div className="error">{error}</div>}
                    {msg && <div className="msg">{msg}</div>}
                    <br/> <br/> 
                </div>

               
             
            </div>

            </form>
        </div>
           
        </main>
    )

}

export default EditBusiness;