import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';

const Header = () => {
     return (
        <>
       <nav  className="navbar navbar-expand-md navbar-dark bg-success" >
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">BohraNetwork.com</Link>
                <button 
                className="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav  ml-auto">
                    <li className="nav-item">
                         <Link className="nav-link" aria-current="page" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                         <Link className="nav-link" to="/contact">Contact</Link>
                    </li>
                   
                </ul>
                </div>
            </div>
        </nav>
        </>
     );
}

export default Header;