import Header from './common/Header';
import Home from './Home';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import About from "./About";
import Contact from './Contact';
import NotFound from './NotFound';
import BusinessDetail from './BusinessDetail';
import AdminHeader from './common/AdminHeader';
import AdminLogin from './AdminLogin';
import { useAuthContext } from './hooks/useAuthContext';
import AdminHome from './admin/AdminHome';
import ManageCity from './admin/city/ManageCity';
import ManageBusiness from './admin/business/ManageBusiness';
import EditBusiness from './admin/business/EditBusiness';
import City from './City';


function App() {
  const {user} = useAuthContext();

  return (
   
    <BrowserRouter>
       
       <Routes>
                  <Route
                  path='/'
                  element={<><Header/><Home /></>}
                  />
                  <Route
                  path='/:id'
                  element={<><Header/><City /></>}
                  />

                   <Route
                  path='/:city/:id'
                  element={<><Header/><BusinessDetail /></>}
                  />
                  <Route
                  path='/about'
                  element={<><Header/><About/></> }
                  />
                   <Route
                  path='/contact'
                  element={<><Header/><Contact/></> }
                  />
                  <Route
                  path='/admin'
                  element={
                    user ?
                    <> 
                    <AdminHeader/>
                    <AdminHome />
                    </> 
                    :
                    <>
                    <AdminHeader/>
                    <AdminLogin/>
                    </>
                    }
                  />

                  <Route
                  path='/admin/city'
                  element={
                    user ?
                    <> 
                    <AdminHeader/>
                    <ManageCity />
                    </> 
                    :
                    <>
                    <AdminHeader/>
                    <AdminLogin/>
                    </>
                    }
                  />

                <Route
                  path='/admin/business'
                  element={
                    user ?
                    <> 
                    <AdminHeader/>
                    <ManageBusiness />
                    </> 
                    :
                    <>
                    <AdminHeader/>
                    <AdminLogin/>
                    </>
                    }
                  />

                  <Route
                  path='/admin/edit_business/:id'
                  element={
                    user ?
                    <> 
                    <AdminHeader/>
                    <EditBusiness />
                    </> 
                    :
                    <>
                    <AdminHeader/>
                    <AdminLogin/>
                    </>
                    }
                  />

                  
                   <Route
                  path='*'
                  element={<><Header/><NotFound /></> }
                  />
      </Routes>
     
    </BrowserRouter>
  );
}

export default App;
