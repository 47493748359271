import { useState } from "react";
import { useAuthContext } from '../../hooks/useAuthContext';
import { useCityContext } from "./useCityContext";
import API_URL from "../../api/ApiUrl";

const AddCityForm = () => {
    const {user} = useAuthContext();
    const {dispatch} = useCityContext();

    const [title,setTitle] = useState('');
    const [country,setCountry] = useState('');
    const [error,setError] = useState(null);
    const [msg,setMsg] = useState(null);
    const [emptyFields,setEmptyFields] = useState([]);

        
    const handleSubmit = async (e) => {
        
        e.preventDefault();

        try {
            if(!user) {
                setError("You must be logged in");
                return;
            }
    
            const cityData = {title, country};
    
            const response = await fetch(API_URL.POST_CITY,{
                method: 'POST',
                headers:{
                    'Content-Type' : 'application/json',
                    'Authorization': `Bearer ${user.token}` 
                },
               
                body: JSON.stringify(cityData),
               
               
            });
            const json = await response.json();
            if(!response.ok){
                setMsg('');
                setError(json.error);
                if(json.requiredFields){
                    setEmptyFields(json.requiredFields);
                    console.log(json.requiredFields);
                }               
            }
            if(response.ok){
                setTitle('');
                setCountry('');
                setError(null);
                setMsg(json.msg);
                setEmptyFields([]);
                console.log("New city added",json);
                dispatch({type:'CREATE_CITY',payload:json});
        
            }
        } catch (error) {
            setError(error.message);
        }

        

    }


    return (
        <main className="form-signin">
                <div className="px-4 py-5 text-center" >
                 <form onSubmit={handleSubmit}>
                 <h1 className="h3 mb-3 fw-normal">Add City</h1>
                  
                    <div className="form-floating ">
                        <input 
                        type="text" 
                        id="cityLabel"
                        className={`form-control ${emptyFields.includes('title')? 'error':''}`}
                        onChange={(event)=> setTitle(event.target.value)}
                        value={title}
                        />
                        <label for="cityLabel">City</label>
                    </div>
                    <div className="form-floating ">
                        <input 
                        type="text" 
                        className={`form-control ${emptyFields.includes('country')? 'error':''}`}
                        onChange={(event)=> setCountry(event.target.value)}
                        value={country}
                        />
                       <label for="countryLabel">Country</label>
                    </div>
                    <br></br>
                    <button className="btn btn-primary">Add City</button>
                    {error && <div className="error">{error}</div>}
                    {msg && <div className="msg">{msg}</div>}

                </form>
               </div>
            </main>
           
    )

}

export default AddCityForm;