import { useState, useEffect } from 'react';

const useFetch = (url) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchData = async () => {
      const abortCont = new AbortController();

     const response = await fetch(url, { signal: abortCont.signal })
     .then(res => {
       if (!res.ok) { // error coming back from server
        setIsPending(false);
        setData(null);
        throw Error('No data found!');

       } 
       return res.json();
     })
     .then(data => {
       setIsPending(false);
       setData(data);
       setError(null);
     })
     .catch(err => {
       console.log(err);
       if (err.name === 'AbortError') {
         console.log('fetch aborted')
       } else {
         // auto catches network / connection error
         setIsPending(false);
         setError(err.message);
       }
     });

      // abort the fetch
      return () => abortCont.abort();
    }

    fetchData();
     }, [url])

  return { data, isPending, error };
}
 
export default useFetch;