import { useState, useEffect } from 'react';
import BusinessRow from './BusinessRow';
import API_URL from '../../api/ApiUrl';
import AddBusinessForm from './AddBusinessForm';
import {useBusinessContext } from './useBusinessContext';

const ManageBusiness = () => {

    const {businesses, dispatch} = useBusinessContext();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    //const [allBusiness,setAllBusiness] = useState(null);

    useEffect(()=>{

        const fetchAllBusiness = async () => {
            const response = await fetch(API_URL.FETCH_ALL_BUSINESS + "?p=" + currentIndex);
            const json = await response.json();
    
            if(response.ok){
              setIsPending(false);
              setError(null);
              dispatch({type:'SET_BUSINESSES',payload:json});
              //setAllBusiness(json);
            }
            else{
              //setAllBusiness(null);
              dispatch({type:'SET_BUSINESSES',payload:null});
              setIsPending(false);
              setError('No data found!');
            }
        }
          fetchAllBusiness();
    },[dispatch,currentIndex]);


    const handlePrevClick = () => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
        else{
          alert("No further records");
        }
      };
      
      const handleNextClick = () => {
         
        if(businesses){
            console.log("Data Array: " + JSON.stringify(businesses));
            setCurrentIndex(currentIndex + 1);
          }
          else{
             alert("No further records")
          }
        
      };

    return (
        <div className="container">

        <br></br>
        <h2>Manage Business</h2>
     
        <table className="table table-striped">
            <thead>
                <tr key={1}>
                <th scope="col">#</th>
                <th scope="col">Title</th>
                <th scope="col">City</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
            {error && <tr>{error}</tr>}
            {isPending && <tr>Loading....</tr>}
            { businesses && businesses.map((business) => (
                    <BusinessRow
                    key={business._id}
                    title={business.title}
                    city= {business.city}
                    id= {business._id}
                    />
                ))
            }
               
                     
            </tbody>
            </table>
            { (
                    <nav aria-label="Page navigation example">
                               
                    <ul className="pagination justify-content-center">
                        <li className="page-item"><button className="page-link" onClick={handlePrevClick}>Previous</button></li>
                        <li className="page-item"><button className="page-link" onClick={handleNextClick}>Next</button></li>
                    </ul>

                    </nav>)

            }
            <br></br>
            <hr></hr>
            <AddBusinessForm />
       </div>
  
    )
}

export default ManageBusiness;