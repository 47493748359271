import {createContext, useReducer} from "react";

export const BusinessContext = createContext();

export const businessReducer = (state,action) => {
    switch(action.type){
        case 'SET_BUSINESSES':
            return {
                businesses: action.payload
            }
        
        case 'CREATE_BUSINESS':
            return {
                businesses: [action.payload.business,...state.businesses]
            }

        case 'DELETE_BUSINESS':
            return {
                businesses: state.businesses.filter((business) => business._id !== action.payload._id)
            }

        default:
            return state
    }
}

export const BusinessContextProvidor = ({children}) =>{
    const [state,dispatch] = useReducer(businessReducer,{
        businesses: null,
    });

    return (
        <BusinessContext.Provider value={{...state,dispatch}}>
            {children}
        </BusinessContext.Provider>
    )
}