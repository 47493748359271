import { useParams } from 'react-router';
import API_URL from './api/ApiUrl';
import useFetch from './api/useFetch';

const BusinessDetail = () => {
    const {id} = useParams(); 

    const {error, isPending, data} = useFetch(API_URL.FETCH_SINGLE_BUSINESS + "/" + id);


    return (
        <div className="px-4 py-5 container">
            { error && <div>{ error }</div> } 
            { isPending && <div>Loading data please wait...</div> }

            { data && ( <div className="row">
                <div className="col-sm-6">
                    <div className="project-info-box mt-0">
                        <h5 className='fullCase'>{data[0].title}</h5>
                        <p className="mb-0">{data[0].about}</p>
                    </div>
                    <br></br>
                    <div className="project-info-box">
                        {data.website_url && <p><b>Website:</b> <a target='_blank' href={data[0].website_url}>{data[0].website_url}</a> </p> }
                        <p className="mb-0"> <a target='_blank' href={data[0].whatsapp_number} className="btn btn-success">Contact Whatsapp</a> </p>
                    </div>

                    <div className="project-info-box mt-0 mb-0">
                        <p className="mb-0"></p>
                    </div>
                </div>

                <div className="col-sm-6">
                    <br></br>
                   {data[0].video_url &&  <iframe  className="col-md-12" height={300} src={data[0].video_url}  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                   }
                    <div className="project-info-box">
                       {data[0].tags && <p><b>Keywords:</b> {data[0].tags}</p>}
                        <p className='capitalize'><b>City:</b> {data[0].city}, {data[0].country}</p>
                    </div>
                </div>
            </div>
            ) }
     </div>    
    ) 
}

export default BusinessDetail;