import API_URL from '../../api/ApiUrl';
import useFetch from '../../api/useFetch';
import AddCityForm from './AddCityForm';
import CityRow from './CityRow';
import { useState, useEffect } from 'react';
import { useCityContext } from './useCityContext';

const ManageCity = () => {

    const {cities, dispatch} = useCityContext();

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
   // const {error, isPending, data} = useFetch(API_URL.FETCH_ALL_CITY + "?p=" + currentIndex);


   useEffect(()=>{

    const fetchCities = async () => {
        const response = await fetch(API_URL.FETCH_ALL_CITY + "?p=" + currentIndex);
        const json = await response.json();

        if(response.ok){
          setIsPending(false);
          setError(null);
           dispatch({type:'SET_CITIES',payload:json});
        }
        else{
          dispatch({type:'SET_CITIES',payload:null});
          setIsPending(false);
          setError('No data found!');
        }
    }
      fetchCities();
},[dispatch,currentIndex]);

   
    const handlePrevClick = () => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
        else{
          alert("No further records");
        }
      };
      
      const handleNextClick = () => {
          if(cities){
            console.log("Data Array: " + JSON.stringify(cities));
            setCurrentIndex(currentIndex + 1);
          }
          else{
             alert("No further records")
          }
      };

    return (
       <div className="container">

        <br></br><br></br>
        <h2>Manage City</h2>

        <table className="table table-striped">
            <thead>
                <tr key={1}>
                <th scope="col">#</th>
                <th scope="col">City</th>
                <th scope="col">Country</th>
                <th scope="col">Delete</th>
                </tr>
            </thead>
            <tbody>
            {error && <tr>{error}</tr>}
            {isPending && <tr>Loading....</tr>}
            { cities && cities.map((city) => (
               <CityRow
               key={city._id}
               id={city._id}
               title= {city.title}
               country = {city.country}
               />
                 ))
          }
           
            </tbody>
            </table>
            { (
                    <nav aria-label="Page navigation example">
                               
                    <ul className="pagination justify-content-center">
                        <li className="page-item"><button className="page-link" onClick={handlePrevClick}>Previous</button></li>
                        <li className="page-item"><button className="page-link" onClick={handleNextClick}>Next</button></li>
                    </ul>

                    </nav>)

            }
            <br></br>
            <br></br>
            <hr></hr>
            <AddCityForm />
       </div>
    )
}

export default ManageCity;