const URI = 'https://bohranetwork.com/server/';
export const SUPPORT_NUMBER ="https://wa.link/7sqlc2";
export const SIGNUP_FORM = "https://forms.gle/ytWmAuCMn4GcXH6J7";
//const URI =  'http://localhost:5000/';

const API_URL = {
    'FETCH_ALL_BUSINESS':  URI + 'api/business/',
    'FETCH_SINGLE_BUSINESS_BY_ID': URI + 'api/business/',
    'FETCH_SINGLE_BUSINESS': URI + 'api/business/city',
    'POST_ADMIN_LOGIN': URI + 'api/adminuser/login',
    'FETCH_ALL_CITY': URI + 'api/city/',
    'POST_CITY':  URI + 'api/city/',
    'DELETE_CITY': URI +  'api/city/',
    'POST_BUSINESS': URI + 'api/business/',
    'DELETE_BUSINESS': URI + 'api/business/',
    'PATCH_BUSINESS': URI + 'api/business/',

}

Object.freeze(API_URL);

export default API_URL;