import Container from 'react-bootstrap/Container';
import { SIGNUP_FORM, SUPPORT_NUMBER } from './api/ApiUrl';
const About = () => {
    return (
        <Container>
             <br></br>
             <h1>About Us</h1> 
             <p>
             Are you an entrepreneur, startup owner, or running a home-based business? We invite you to share your business journey, perseverance, and success story with us. Inspire other community members to start their ventures or create employment opportunities through a video blog that will be featured on our website and social media platforms. Our team is available to meet with you in person or connect through virtual meeting platforms. Additionally, you can benefit from our expertise and advice in the following areas:
             </p> 
             <p>
                 Enhancing your online presence through websites and social media platforms.
                HR services, including payroll support, recruitment, drafting HR policies, SOPs, KRAs, etc.
                One-time registrations such as: <br></br>
                <ul>
                    <li> A. Shop Act Registration</li>
                    <li> B. GST Registration</li>
                    <li> C. Udyam Aadhar Registration</li>
                    <li> D. Import Export Code Registration</li>
                    <li> E. Trademark Registration</li>
                    <li> F. Private Limited Company / LLP / Partnership Firm registration</li>
                    <li> G. Startup India Registration</li>
                </ul>
                All services related to Company Secretary.
                You can contact us at: <a target='_blank' href={SIGNUP_FORM} className="">Google Form</a>
             </p>
        </Container>
    )
}

export default About;