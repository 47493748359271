import Container from 'react-bootstrap/Container';

const NotFound = () => {
    return (
        <Container>
              <br></br>
             <h1>Page not found</h1>  
        </Container>
    );
}

export default NotFound;