
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { CityContextProvidor } from './admin/city/CityContext';
import { BusinessContextProvidor } from './admin/business/BusinessContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>    
    <AuthContextProvider>
      <BusinessContextProvidor>
        <CityContextProvidor>
            <App />
        </CityContextProvidor>
      </BusinessContextProvidor>
    </AuthContextProvider>
  </React.StrictMode>
);