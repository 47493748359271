import { Component } from "react"
import { Link } from 'react-router-dom';

const BusinessComponent = ({id,city,title,video_url,about}) =>  {
    return (
        <>
             <div className="card">
                {video_url && <iframe className="iframeHome" src={video_url} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                }
                <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text textOverflowStyle">{about}</p>
                    <Link to={"/" + city + "/" + id} className="btn btn-success">Details</Link>
                </div>
            
            </div>
            <br></br>
        </>
       
    )
}

export default BusinessComponent;