import { useState } from "react";
import { useAdminLogin } from "./hooks/useAdminLogin";


const AdminLogin = () => {

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const {adminLogin, error, isLoading} = useAdminLogin();
    
    const handleSubmit = async (e) => {

        e.preventDefault();
        await adminLogin(email,password);
    }
    
    return (
        <main className="form-signin">
             <div className="px-4 py-5 text-center" onSubmit={handleSubmit}>
                <form>
                    <h1 className="h3 mb-3 fw-normal">Admin Login</h1>

                    <div className="form-floating ">
                        <input 
                        type="email" 
                        className="form-control" 
                        id="floatingInput" 
                        value={email}
                        onChange={(element) => setEmail(element.target.value)}
                        />
                        <label for="floatingInput">Email</label>
                    </div>
                    <div className="form-floating">
                    <input 
                        type="password" 
                        className="form-control" 
                        id="floatingPassword" 
                        placeholder="Password"
                        value={password}
                        onChange={(element) => setPassword(element.target.value)}
                    />
                    <label for="floatingPassword">Password</label>
                    </div>            
                
                    <button disabled={isLoading} className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                    {error && <div className="error">{error}</div>}
                </form>
             </div>
           
        </main>

    )
}


export default AdminLogin;