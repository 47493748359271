import {createContext, useReducer} from "react";

export const CityContext = createContext();

export const cityReducer = (state,action) => {
    switch(action.type){
        case 'SET_CITIES':
            return {
                cities: action.payload
            }
        
        case 'CREATE_CITY':
            return {
                cities: [action.payload.city,...state.cities]
            }

        case 'DELETE_CITY':
            return {
                cities: state.cities.filter((city) => city._id !== action.payload._id)
            }

        default:
            return state
    }
}

export const CityContextProvidor = ({children}) =>{
    const [state,dispatch] = useReducer(cityReducer,{
        cities: null,
    });

    return (
        <CityContext.Provider value={{...state,dispatch}}>
            {children}
        </CityContext.Provider>
    )
}