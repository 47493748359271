const AdComponent = ({title,body,href}) => {

    return (
        <>
            <div className="card">
                <h5 className="card-header">{title}</h5>
                <div className="card-body">
                    <p className="card-text">{body}</p>
                    <a href={href} target="_blank" className="btn btn-success">Contact Whatsapp</a>
                </div>
            </div>
            <br/>
        </>
    )
}

export default AdComponent;