import BusinessComponent from './utility/BusinessComponent';
import AdComponent from './utility/AdComponent';
import useFetch from './api/useFetch';
import API_URL, { SIGNUP_FORM, SUPPORT_NUMBER } from './api/ApiUrl';
import { useState } from 'react';

const Home = () => {

   
    const [currentIndex, setCurrentIndex] = useState(0);
    const {error, isPending, data} = useFetch(API_URL.FETCH_ALL_BUSINESS + "?p=" + currentIndex);
    
   
    const handlePrevClick = () => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
        }
        else{
          alert("No further records");
        }
      };
      
      const handleNextClick = () => {
        if (data) {
          setCurrentIndex(currentIndex + 1);
        }
        else{
          alert("No further records")
        }
      };


    //console.log(data);

    return (
        <main className="flex-shrink-0">
           <div className="px-4 py-5 text-center">
                <h1 className="display-5 fw-bold">Local Success Stories</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">Promoting bohra entrepreneurs and local businesses</p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                        <a target='_blank' href={SIGNUP_FORM} className="btn btn-success btn-lg px-4 gap-3">Contact Now</a>
                    </div>
                </div>
           </div>

           <div className="container">
            <div className="row">

                <div className="col-sm-7">
                   { error && <div>{ error }</div> } 
                   { isPending && <div>Loading data please wait...</div> }

                   { data && data.map(business => (
                       
                             <BusinessComponent 
                                key={business._id}
                                id={business.seo_name}
                                city= {business.city}
                                title= {business.title}
                                video_url={business.video_url}
                                about = {business.about}
                             />

                        )
                      
                   )}
                   { (
                    <nav aria-label="business pagination">
                               
                    <ul className="pagination justify-content-center">
                        <li className="page-item"><button className="page-link" onClick={handlePrevClick}>Previous</button></li>
                        <li className="page-item"><button className="page-link" onClick={handleNextClick}>Next</button></li>
                    </ul>

                    </nav>)

                   }
                </div>
                <div className="col-sm-4">
                    <h5>Sponcered Ads</h5>        
                    <AdComponent 
                    title={"Pune To Mumbai & All Outstation Cabs"}
                    body = {"24x7 Cab service from Pune to Mumbai. Pickup and drop service door to door. All outstation and ziarat cabs available. Ertiga, Innova, BYD Electric & Sedan cabs available"}
                    href={"https://wa.link/5pwqe9"}
                   />               
                </div>
                
            </div>
            </div>
            <br/>
        </main>
    )
}

export default Home;