import { useAuthContext } from "../../hooks/useAuthContext";
import { useBusinessContext } from "./useBusinessContext";
import API_URL from '../../api/ApiUrl';
import { Link } from 'react-router-dom';

const BusinessRow = ({id,title,city}) => {
    const {user} = useAuthContext();
    const {dispatch} = useBusinessContext();

    const handleClick = async () =>{

        if(!user) {
            return;
        }

        const response = await fetch(API_URL.DELETE_BUSINESS + id,{
            method:'DELETE',
            headers:{
                'Authorization': `Bearer ${user.token}` 
            }
        });
        const json = await response.json();
        console.log(json);
        if(response.ok){
            dispatch({type:'DELETE_BUSINESS',payload:json});
        }

    }



    return (
        <tr key={id}>
            <th scope="row">{id}</th>
            <td>{title}</td>
            <td>{city}</td>
            <td><span><Link className="nav-link" aria-current="page" to={`/admin/edit_business/${id}`}>Edit</Link></span></td>
            <td><span onClick={handleClick}>Delete</span></td>
        </tr>
    )
}

export default BusinessRow;