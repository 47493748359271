import { Link } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';

const AdminHeader = () => {

    const { logout } = useLogout();
    const {user} = useAuthContext();

    const handleClick = () => {
        logout();
    }


     return (
        <>
       <nav  className="navbar navbar-expand-md navbar-dark bg-dark" >
            <div className="container-fluid">
                <Link className="navbar-brand" to="/admin">Admin BohraNetwork.com</Link>
                <button 
                className="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav  ml-auto">
                    <li className="nav-item">
                         <Link className="nav-link" aria-current="page" to="/admin">Home</Link>
                    </li>
                    {
                        user && (

                    <li className="nav-item">
                         <Link className="nav-link" aria-current="page" to="/admin/business">Manage Business</Link>
                    </li>)
                    }

                    {
                    user && (

                    <li className="nav-item">
                         <Link className="nav-link" aria-current="page" to="/admin/city">Manage City</Link>
                    </li>
                        )
                    }
                 
                    {
                        user && (
                            <li className="nav-item">
                                <button className="nav-link" onClick={handleClick}>Logout ({user.email})</button>
                            </li>
                        )
                    }
                 
                   
                </ul>
                </div>
            </div>
        </nav>
        </>
     );
}

export default AdminHeader;