import { createContext,useEffect,useReducer } from "react";

export const AuthContext = createContext();

export const authReducer = (state,action) => {

    switch(action.type)
    {
        case 'ADMIN_LOGIN':
        return {user: action.payload}

        case 'ADMIN_LOGOUT':
            return {user:null}

        default:
            return state;    
    }
}

export const AuthContextProvider = ({children}) => {
    const [state,dispatch] = useReducer(authReducer,{
        user:null
    });

    useEffect(()=>{
        const user = JSON.parse(localStorage.getItem('user'));
        if(user){
            dispatch({type:'ADMIN_LOGIN', payload:user});
        }

    },[]);

    console.log('AuthContext State ', state);

    return (
        <AuthContext.Provider value={{...state,dispatch}}>
            {children}
        </AuthContext.Provider>
    )
}
