import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import API_URL from "../api/ApiUrl";



export const useAdminLogin = () =>{
    const [error,setError] = useState(null);
    const [isLoading,setIsLoading] = useState(null);
    const {dispatch} = useAuthContext();

    const adminLogin = async (email,password) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch(API_URL.POST_ADMIN_LOGIN,{
            method: 'POST',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify({email,password})
        });

        const json = await response.json();

        if(!response.ok){
            setIsLoading(false);
            setError(json.error);
        }

        if(response.ok){
            //save the user to the local storage
            localStorage.setItem('user',JSON.stringify(json));
            dispatch({type:'ADMIN_LOGIN',payload:json});
            setIsLoading(false);

        }
    }

    return {adminLogin, isLoading, error};
}