import Container from 'react-bootstrap/Container';
import { SIGNUP_FORM, SUPPORT_NUMBER } from './api/ApiUrl';
const Contact = () => {
    return (
        <Container>
            <br></br>
             <h1>Contact Us</h1>  
             <p>For any questions or inquiries, please contact us on <a target='_blank' href={SIGNUP_FORM} className="">Google Form</a>.</p>
        </Container>
    );
}

export default Contact;