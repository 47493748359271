const AdminHome = () => {
    return (
        <div class="px-4 pt-5 my-5 text-center border-bottom">
            <h1 class="display-4 fw-bold">WELCOME ADMIN </h1>
            <div class="col-lg-6 mx-auto">
                <p class="lead mb-4"> You are logged in as an admin. Manage the content from here.</p>
            </div>
        </div>
        
    )
}

export default AdminHome;